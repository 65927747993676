<!--
 * @Descripttion: 注册页面
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-08-30 14:12:28
 * @LastEditTime: 2021-10-12 19:41:54
-->
<template>
  <div>
    <Header />
    <div class="contentDiv">
      <el-form ref="regisrerFrom"
               :model="form"
               :rules="rules"
               class="login-box">
        <h3 class="login-title">注册犀利交易平台</h3>
        <el-form-item prop="username">
          <el-input type="text"
                    prefix-icon="el-icon-user"
                    placeholder="请输入账号"
                    v-model="form.username" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入密码"
                    v-model="form.password" />
        </el-form-item>
        <el-form-item prop="password2">
          <el-input type="password"
                    prefix-icon="el-icon-lock"
                    placeholder="请输入确认密码"
                    v-model="form.password2" />
        </el-form-item>
        <el-form-item prop="phone">
          <el-input type="text"
                    prefix-icon="el-icon-mobile-phone" 
                    placeholder="请输入手机号码"
                    v-model="form.phone" style="float:left;margin-right: -100px;" />
                        <el-button v-if="btnTitle" @click="getCaptcha"  type="primary" plain    style="width: 99px;float: left;height: 38px;margin: 1px 1px 1px 0px;position: relative;border: #ffffff;" :disabled="disabled">{{btnTitle}}</el-button>    
        </el-form-item>
        <el-form-item v-if="isCaptcha"
                      prop="captcha_code">
          <el-input type="text"
                    style="float:left;margin-right: -100px;"
                    placeholder="请输入验证码"
                    v-model="form.captcha_code" />
          <el-image    v-if="false"   @click="getCaptcha1" style="width: 99px;float: left;height: 38px; padding: 1px 1px 1px 0;"
                    :src="captchaImg"></el-image>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading"
                     type="warning"
                     v-on:click="onSubmit('regisrerFrom')">注册</el-button>
          <el-button type="primary"
                     v-on:click="goto('login')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import { getCaptcha,register } from "@/api/register";
import "@/styles/login.css";
export default {
  name: "DbgamewebRegister",

  components: { Header },

  directives: {},

  data() {
    return {
      btnTitle:"获取验证码",
      disabled:false,  //是否可点击
      loading: false,
      isCaptcha: true,
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        username: [
          { required: true, message: "账号不可为空", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不可为空", trigger: "blur" },
        ],
        password2: [
          { required: true, message: "确认密码不可为空", trigger: "blur" },
        ],
        phone: [{ required: true, message: "手机号不可为空", trigger: "blur" }],
        captcha_code: [
          { required: true, message: "验证码不可为空", trigger: "blur" },
        ],
      },
      form: {
        username: "",
        password: "",
        password2: "",
        phone:"",
        captcha_code: "",
        captcha_id: "",
      },
    };
  },

  mounted() {
    // this.getCaptcha();
  },
 
  methods: {
    onSubmit(formName) {
      if(this.validatePhone()){
      // 为表单绑定验证功能
      this.$refs[formName].validate((valid) => {
        this.loading = true;
        if (valid) {
            

            if(this.form.password!=this.form.password2){
                this.$message.error({ message: "输入的两次密码不一致", center: true });
           this.loading = false;
                 return false;
            }
             
          // 使用 vue-router 路由到指定页面，该方式称之为编程式导航
          //this.login();


            register(this.form).then(()=>{
                     delete this.form["password2"]
                     delete this.form["password"]


           this.$message({
                showClose: true,
                message: "注册成功",
                type: "success",
              }); 
                 
              this.$router.push({ path: "/login" });
//    this.$router.push({ path: "/member/person/memberinfo" });
            }).catch(()=>{
              //console.log(error)
                 // delete this.form["password"]
                  //delete this.form["password2"]
                 delete this.form["captcha_code"]
// this.getCaptcha();
             this.loading = false;
            })
            
        
       
        } else {
        //this.$message.error({ message: "输入账号和密码", center: true });
           this.loading = false;
          return false;
        }
      });
      }
    },
    goto(url) {
      this.$router.push("/" + url);
    },
    // 获取验证码
    getCaptcha() {

      if(this.validatePhone()){
  this.validateBtn()
       let params = {
        phone: this.form.phone,
      };
      getCaptcha(params).then((res) => {
        this.form.captcha_id = res.data.captcha_id;
        this.isCaptcha = res.data.captcha_switch;
        this.captchaImg = res.data.captcha_src;
      });
      }
      

   
    },
    validateBtn(){
   //倒计时
   let time = 60;
   let timer = setInterval(() => {
   if(time == 0) {
    clearInterval(timer);
    this.disabled = false;
    this.btnTitle = "获取验证码";
   } else {
    this.btnTitle =time + '秒后重试';
    this.disabled = true;
    time--
   }
  },1000)
},
validatePhone(){
   //判断输入的手机号是否合法
   if(!this.form.phone) {
  
     this.$message.error({ message: "手机号码不能为空" });
  return false
   } else if(!/^1[345678]\d{9}$/.test(this.form.phone)) {

   this.$message.error({ message: "请输入正确是手机号" });
   return false
   } else {
    this.errors ={}
    return true
   }
},           
  },
};
</script>

<style lang="scss" scoped>
</style> 